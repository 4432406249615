import localforage from 'localforage'
import logErr, { logMsg } from './err'

let store

export const initStorage = async () => {
  const supportsLocal = localforage.supports(localforage.LOCALSTORAGE)
  const supportsWebSQL = localforage.supports(localforage.WEBSQL)
  console.log('supportsLocal', supportsLocal, 'supportsWebSQL', supportsWebSQL)
  if (!(supportsLocal || supportsWebSQL)) {
    logErr(new Error('no storage support'))
    return
  }

  try {
    store = localforage.createInstance({
      // leaving out IDB to possibly avoid complications with buggy browser support and conflicts with firebase usage
      driver: [localforage.WEBSQL, localforage.LOCALSTORAGE], // localforage.INDEXEDDB
      name: 'landslide',
      version: 1.0,
      //size: 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: 'kv', // Should be alphanumeric, with underscores.
      // description: '',
    })
    await store.ready()
  } catch (err) {
    logErr(err, 'failed to init storage')
    store = null
    return
  }
  // migrate old IDB values to new storage
  try {
    // create old storage with default name
    const oldStore = localforage.createInstance({})
    await oldStore.ready()

    // await oldStore.setItem('test', new Date().toISOString())

    const keys = await oldStore.keys()
    if (keys.length > 0) {
      logMsg('migrate old forage keys', { keys: keys })
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const value = await oldStore.getItem(key)
        await store.setItem(key, value)
      }
      await oldStore.clear()
    }
  } catch (err) {
    logErr(err, 'failed to migrate old forage keys')
  }
}

export const setItem = async (key, value) => {
  if (store)
    try {
      return await store.setItem(key, value)
    } catch (e) {
      logErr(e, 'localforage setItem failed')
    }
  else {
    logErr(new Error('localforage.setItem called before store initialized'))
  }
}
export const getItem = async (key) => {
  if (store)
    try {
      return await store.getItem(key)
    } catch (e) {
      logErr(e, 'localforage getItem failed')
    }
  else {
    logErr(new Error('localforage.getItem called before store initialized'))
  }
}

export const removeItem = async (key) => {
  if (store)
    try {
      await store.removeItem(key)
      return true
    } catch (e) {
      logErr(e, 'localforage removeItem failed')
      return false
    }
  else {
    logErr(new Error('localforage.removeItem called before store initialized'))
  }
}

export const clear = async () => {
  if (store)
    try {
      await store.clear()
      return true
    } catch (e) {
      logErr(e, 'localforage clear failed')
      return false
    }
  else {
    logErr(new Error('localforage.clear called before store initialized'))
  }
}

export default {
  setItem,
  getItem,
  removeItem,
  clear,
}
